import store from '../store'
import router from '../router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import {
  DEFAULT_LANGUAGE,
  HEADER_LANGUAGE,
  AUTHORIZATION,
  API_SERVER,
  BASE_URL
} from '../constants/constants'

axios.defaults.baseURL = API_SERVER + BASE_URL
axios.interceptors.request.use(function (config) {
  if (store.getters.currentToken) {
    config.headers.common[AUTHORIZATION] = store.getters.currentToken
    config.headers.common[HEADER_LANGUAGE] = DEFAULT_LANGUAGE
  }
  return config
}, function (error) {
  return Promise.reject(error)
})
axios.interceptors.response.use(function (response) {
  if (response.data && response.data.status === 401) {
    store.dispatch('resetAuth')
    router.push('/login')
  }
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    store.dispatch('resetAuth')
    router.push('/login')
  }
  return Promise.reject(error)
})

export default {
  install (Vue) {
    Vue.use(VueAxios, axios)
  }
}
