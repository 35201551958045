<template>
  <b-form-group>
    <label><slot/></label>
    <b-input-group class='date' size="md">
      <datepicker :value="value"
                  :config="getConfig"
                  :wrap="true"
                  :class="addClass"
                  :placeholder="getPlaceholder"
                  @dp-change="$emit('input', $event.date ? $event.date.toDate() : null)">
      </datepicker>
      <div class="input-group-append">
        <button class="btn btn-primary datepickerbutton" type="button">
          <i class="fa fa-calendar-alt"></i>
        </button>
      </div>
    </b-input-group>
  </b-form-group>
</template>

<script>
import Datepicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import $ from 'jquery'
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: Date
    },
    addClass: {
      type: Object
    },
    minimumView: {
      type: String
    },
    maximumView: {
      type: String
    },
    initialView: {
      type: String
    },
    dateFormat: {
      type: String,
      default: () => 'YYYY-MM-DD'
    },
    dateTimeFormat: {
      type: String,
      default: () => 'YYYY-MM-DD hh:mm a'
    },
    timeFormat: {
      type: String,
      default: () => '00:00'
    },
    type: {
      type: String,
      default: () => 'date'
    }
  },
  data: () => {
    return {
      date: new Date(),
      wrap: true,
      configs: {
        timePicker: {
          allowInputToggle: true,
          format: 'LT'
        },
        datePicker: {
          format: 'YYYY-MM-DD',
          allowInputToggle: true,
          useCurrent: true
        },
        dateTimePicker: {
          allowInputToggle: true,
          format: 'YYYY-MM-DD hh:mm a',
          useCurrent: true,
          showClear: true,
          showClose: true
        }
      }
    }
  },
  computed: {
    getConfig () {
      if (this.type === 'date') {
        return this.configs.datePicker
      } else if (this.type === 'time') {
        return this.configs.timePicker
      } else {
        return this.configs.dateTimePicker
      }
    },
    getPlaceholder () {
      if (this.type === 'date') {
        return this.dateFormat
      } else if (this.type === 'time') {
        return this.timeFormat
      } else {
        return this.dateTimeFormat
      }
    }
  },
  methods: {
    showPicker () {

    }
  },
  components: {
    Datepicker
  }
}
</script>
