<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
    import {AUTHORIZATION} from './constants/constants'

    export default {
        name: 'app',
        async created() {
            await this.$store.dispatch('fetch', localStorage.getItem(AUTHORIZATION), {root: true})
                .then(data => {
                    if (data && data.status !== 200) {
                        this.$toastr.error(data.message)
                        this.$router.push('/login')
                    }
                })
        }
    }
</script>

<style lang="scss">
  @import 'assets/scss/style';
  @import 'assets/fontawesome-free/css/all.css';
</style>
