import render from '@/components/helper/render'
import TextBox from '@/components/ui/TextBox'
import SelectBox from '@/components/ui/SelectBox'
import DatePicker from '@/components/ui/DatePicker'
import Textarea from '@/components/ui/Textarea'
import CheckBox from '@/components/ui/CheckBox'
import SwitchBox from '@/components/ui/SwitchBox'
import ConfirmationModal from '@/components/modal/ConfirmationModal'

export default {
  install (Vue) {
    Vue.component('render', render)
    Vue.component('TextBox', TextBox)
    Vue.component('SelectBox', SelectBox)
    Vue.component('DatePicker', DatePicker)
    Vue.component('Textarea', Textarea)
    Vue.component('CheckBox', CheckBox)
    Vue.component('SwitchBox', SwitchBox)
    Vue.component('ConfirmationModal', ConfirmationModal)
  }
}
