import axios from 'axios'
import {resetAuth, setAuth} from './../../utils/auth'
import {API_SERVER, BASE_URL, AUTHORIZATION} from './../../constants/constants'

const state = {
  user: null,
  currentToken: localStorage.getItem(AUTHORIZATION)
}
const mutations = {
  setAuth (state, data) {
    state.currentToken = data
  },
  setUser (state, data) {
    state.user = data
  },
  resetUser (state) {
    state.user = null
    state.currentToken = null
  }
}
const actions = {
  async fetch ({commit, dispatch}, param) {
    if (!param) {
      return null
    }
    let { data } = await axios({
      url: 'member/fetch-me',
      headers: {'X-AUTH-TOKEN': param},
      baseURL: API_SERVER + BASE_URL
    })
    if (data && data.status === 200) {
      const user = {
        firstName: data.data.firstName,
        lastName: data.data.lastName
      }
      commit('setUser', user)
      commit('setAuth', param)
      setAuth(param)
    } else {
      dispatch('resetAuth')
    }
    return data
  },
  login ({commit}, param) {
    return axios.post('auth/activate', param)
      .then(response => {
        const {data} = response
        if (data && data.status === 200) {
          commit('setAuth', data.data.token)
          setAuth(data.data.token)
        }
        return response
      })
  },
  logout () {
    return axios.get('auth/logout')
      .then(response => {
        return response.data.status
      })
  },
  resetAuth ({commit}) {
    commit('resetUser')
    resetAuth()
  }
}
const getters = {
  currentToken: (state) => {
    return state.currentToken
  },
  getUserFullName: (state) => {
    let fullName = ''
    if (!state.user) {
      return fullName
    }
    if (state.user.firstName) {
      fullName = state.user.firstName
    }
    if (state.user.lastName) {
      fullName += ' ' + state.user.lastName
    }
    return fullName
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
