<template>
  <b-form-group>
    <label :class="{'required': required}"><slot/></label>
    <b-form-input v-bind="$attrs"
                  :value="value"
                  :class="addClass"
                  :placeholder="getPlaceholder"
                  @focus="resetPlaceholder"
                  @blur="setPropPlaceholder"
                  @input="$emit('input', $event)">
    </b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    value: null,
    placeholder: {
      type: String,
      default: () => ''
    },
    addClass: {
      type: Object
    }
  },
  computed: {
    getPlaceholder () {
      return this.placeholder
    }
  },
  methods: {
    setPropPlaceholder (event) {
      event.target.placeholder = this.placeholder
    },
    resetPlaceholder (event) {
      event.target.placeholder = ''
    }
  }
}
</script>

<style scoped>

</style>
