<template>
  <b-form-group>
    <label :class="{'required': required}"><slot/></label>
    <b-form-checkbox v-bind="$attrs"
                     :checked="value"
                     :class="addClass"
                     @input="$emit('input', $event)"
                     size="lg"
                     switch>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  name: 'SwitchBox',
  props: {
    addClass: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
