<template>
  <b-form-group>
    <label :class="{'required': required}"><slot/></label>
    <b-form-textarea v-bind="$attrs"
                  :value="value"
                  :class="addClass"
                  @input="$emit('input', $event)">
    </b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    addClass: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
