<template>
    <b-modal v-model="showModal" size="sm" centered
             :title="$t('confirmation')"
             :ok-title="$t('yes')"
             :cancel-title="$t('no')"
             @cancel="$emit('cancel')"
             @ok="$emit('yes')">
        {{ $t('deleteMessage') }}
    </b-modal>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    show () {
      this.showModal = true
    },
    hide () {
      this.showModal = false
    }
  }
}
</script>

<style scoped>

</style>
