import store from '../store'

const beforeEnter = (to, from, next) => {
  if (!store.getters.currentToken) {
    next('/login')
  } else {
    next()
  }
}

const uniqueId = () => {
  const _id = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  }
  return _id() + '-' + _id()
}

export default beforeEnter
export {
  uniqueId
}
