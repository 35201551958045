<template>
  <b-form-group :class="formGroupClass">
    <label :class="{'required': required}"><slot/></label>
    <b-form-select v-bind="$attrs"
                   :options="items"
                   :value="value"
                   :plain="true"
                   :class="addClass"
                   :disabled="disabled"
                   @input="$emit('input', $event)">
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: 'SelectBox',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: String,
    disabled: {
      type: Boolean,
      default: () => false
    },
    addClass: {
      type: Object
    },
    formGroupClass: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped>

</style>
