import Vue from 'vue'
import Router from 'vue-router'
import beforeEnter from '@/utils'

const LoginPage = () => import('@/pages/LoginPage')
const DefaultLayout = () => import('@/layouts/DefaultLayout')
const CategoriesListPage = () => import('@/pages/category/CategoryListPage')
const PlacesListPage = () => import('@/pages/place/PlacesListPage')
const CategoriesAddPage = () => import('@/pages/category/CategoryAddPage')
const PlacesAddPage = () => import('@/pages/place/PlacesAddPage')
const CategoriesEditPage = () => import('@/pages/category/CategoryEditPage')
const PlacesEditPage = () => import('@/pages/place/PlacesEditPage')
const CategoriesSummaryPage = () => import('@/pages/category/CategorySummaryPage')
const PlacesSummaryPage = () => import('@/pages/place/PlacesSummaryPage')
const UsersListPage = () => import('@/pages/user/UsersListPage')
const BroadcastListPage = () => import('@/pages/push-notification/BroadcastListPage')
const BroadcastAddEditPage = () => import('@/pages/push-notification/AddEditBroadcastPage')
const ActionsLog = () => import('@/pages/log/ActionsLog')

const NotFoundPage = () => import('@/pages/NotFoundPage.vue')

Vue.use(Router)

export default new Router({
    mode: 'hash',
    linkActiveClass: 'open active',
    linkExactActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            name: 'Home',
            redirect: '/categories',
            component: DefaultLayout,
            beforeEnter: beforeEnter,
            children: [
              {
                path: 'categories',
                name: 'Categories',
                redirect: '/categories/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view')
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'CategoriesListPage',
                    component: CategoriesListPage
                  },
                  {
                    path: 'add',
                    name: 'CategoriesAdd',
                    component: CategoriesAddPage
                  },
                  {
                    path: 'edit/:id',
                    name: 'CategoriesEdit',
                    component: CategoriesEditPage
                  },
                  {
                    path: 'summary/:id',
                    name: 'CategoriesSummary',
                    component: CategoriesSummaryPage
                  }
                ]
              },
              {
                path: 'places',
                name: 'Places',
                redirect: '/places/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view')
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'PlacesList',
                    component: PlacesListPage
                  },
                  {
                    path: 'add',
                    name: 'PlacesAdd',
                    component: PlacesAddPage
                  },
                  {
                    path: 'edit/:id',
                    name: 'PlacesEdit',
                    component: PlacesEditPage
                  },
                  {
                    path: 'summary/:id',
                    name: 'PlacesSummary',
                    component: PlacesSummaryPage
                  }
                ]
              },
              {
                    path: 'user',
                    name: 'Users',
                    redirect: '/user/list',
                    beforeEnter: beforeEnter,
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'list',
                            name: 'UsersListPage',
                            component: UsersListPage
                        },
                    ]
                },
              {
                path: 'broadcast',
                name: 'Broadcast',
                redirect: '/broadcast/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view')
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'BroadcastList',
                    component: BroadcastListPage
                  },
                  {
                    path: 'add',
                    name: 'BroadcastAdd',
                    component: BroadcastAddEditPage
                  },
                  {
                    path: 'edit/:id',
                    name: 'BroadcastEdit',
                    component: BroadcastAddEditPage
                  }
                ]
              },
              {
                path: 'log-history',
                name: 'Log',
                redirect: '/log-history/list',
                beforeEnter: beforeEnter,
                component: {
                  render(c) {
                    return c('router-view')
                  }
                },
                children: [
                  {
                    path: 'list',
                    name: 'ActionsLog',
                    component: ActionsLog
                  }
                ]
              }
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFoundPage
        },
        {
            path: '*',
            component: NotFoundPage
        }
    ]
})
